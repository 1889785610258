@keyframes popup-animation {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.popup-animation {
  animation: popup-animation 0.5s ease-in-out;
}

.Login_Best {
  background: linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%);
  display: flex;
  justify-content: center;
  align-items: center;
}