/* Scrollbar Styles */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #3B3F49; /* Track color */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #72787C; /* Thumb color */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #3B3F49; /* Border around the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Thumb color on hover */
  }
  /* Hide the scrollbar but enable scrolling */
::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  