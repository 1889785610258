html,
body {
  position: relative;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.mySwiper {
  height: 10%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 4px solid #FFBE41;
}

.swiper-slide img {
  display: block;
}