/* CSS */
.horizontal-slider {
    width: 80%;
    /* Adjust the width as needed */
    margin: 20px auto;
    height: 5px;
    background-color: #ccc;
    border-radius: 5px;
}

.example-track {
    background-color: #007bff;
    /* Blue track color */
    height: 5px;
    border-radius: 5px;
}

.icon_down_dir {
    right: 14px;
    width: 2rem !important;
}

.icon_down_dir::before {
    content: none !important
}

.example-thumb {
    top: -8px;
    min-width: 20px;
    min-height: 20px;
    background-color: #007bff;
    border: 2px solid #007bff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.example-thumb:active {
    cursor: grabbing;
}

.multiselectContainer {}

.searchBox {
    border: none;
    font-size: 10px;
    min-height: 50px;
}

.inputField {
    /* margin: 5px; */
}

.searchWrapper {
    outline: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid #bbb;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    font-size: 1rem;
    border-radius: 10px !important;
    min-height: 68px !important;
    display: flex !important;
    align-items: center !important;
}

.searchBox {
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
}

.chip {
    overflow: hidden;
    background: #3b3f49 !important;
    height: 44px;
    min-width: 61px;
    font-size: 18px;
    display: flex;
    justify-content: space-around;
}

.optionListContainer {
    border-radius: 10px !important;
}

.optionContainer {
    border-radius: 10px !important;
    border: 2px solid;
    background-color: #BBBBBB;
}

.highlightOption {
    /* background: transparent !important;
    color: black !important; */
}

.highlight {}

.option {
    color: black;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #666666;
    margin: 0 5%;
}

.option:hover {
    background-color: #BBBBBB !important;
    /* This removes the bottom border on hover */
    color: black !important;
}

.groupHeading {}