.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Choose_Best {
  background: linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%);
}

#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 4px;
  background: #FFFFFF;
  /* opacity: 0.3; */
  border-radius: 10px;
}

.swiper-pagination-bullet-active {
  width: 50px;
  height: 4px;
  background: #FFBA40;
  border-radius: 10px;
}

.swiper-button-next,
.swiper-button-prev {
  /* width: 200px;
  height: 200px; */
  color: transparent;
}

.swiper-button-prev {
  /* top: 40%; */
  /* margin-left: 2%; */
}

.swiper-button-next {
  /* top: 40%; */
  /* margin-right: 2%; */
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: unset;
}