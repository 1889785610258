.car-comparison {
    /* width: 100%; */
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none; 
}

.car-list {
    display: flex;
    width: fit-content;
    white-space: nowrap;
}
/* .car-comparison::-webkit-scrollbar {
    display: none; 
} */
.car-comparison::-webkit-scrollbar-thumb {
    background-color: #959595 !important;
}
.BGForButtons{
    background: linear-gradient(266.46deg, #FFBE41 48.38%, #FF9334 106.22%);
}