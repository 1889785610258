.custom-search-control {
    /* Add your custom CSS rules here */
}

.leaflet-control-container {
    /* width: 800px;  */
}

.leaflet-geosearch-bar {
    position: relative;
    display: block;
    height: auto;
    max-width: calc(100% - 120px);
    margin: 10px auto 0;
    cursor: auto;
    z-index: 1000;
    box-sizing: border-box;

    background: #4B4E58;
    opacity: 0.8;
    border-radius: 8px;

}

.leaflet-geosearch-bar form {
    background: #4B4E58;
    opacity: 0.8;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-geosearch-bar form .glass {
    background: #4B4E58;
    opacity: 0.8;
    border-radius: 8px;
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 350;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #BBBBBB;
}

.leaflet-geosearch-bar form .reset {
    background: #4B4E58;
    opacity: 0.8;
    border-radius: 8px;
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 350;
    font-size: 18px;
    line-height: 22px;
    color: #BBBBBB;
    top: unset;
}

.leaflet-geosearch-bar form .active {
    position: absolute;
    top: 104%;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: unset;
    background-clip: padding-box;
}

.dummy_search {
    z-index: 999;
    background-color: red;
}

.dummy_2search {
    z-index: 9;
    background-color: red;
}