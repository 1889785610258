.App {
  text-align: center;
}
.center_aline{
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.shade-color{
  background: linear-gradient(266.46deg, #FF9334 5.25%, #FFBE41 69.94%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fit-content {
  height: fit-content;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: Gotham Rounded;
  src: url('../public/font/GothamRounded-Medium.otf');
}

.Def-font{
  font-family: Gotham Rounded;
}

/* YourComponent.module.css */

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 80px;
  height: 80px;
  position: relative;
  animation: rotate 2s linear infinite;
}

.spinner-circle {
  width: 100%;
  height: 100%;
  border: 6px solid transparent;
  border-top-color: #3498db; /* Change the color to your preference */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  color: #3498db; /* Change the color to your preference */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-datepicker-wrapper{
  width: 100% !important;
}

.react-datepicker__triangle{
  transform: translate3d(115px, 0px, 0px) !important;
}

.searchWrapper {
  flex-wrap: wrap;
}

.video-container {
  position: relative;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: url('./assets/playbutton.svg') center/cover no-repeat; /* Replace with your play button icon */
  cursor: pointer;
}

.loader {
  border: 8px solid rgba(255, 255, 255, 0.3); /* Light grey border */
  border-top: 8px solid #ffba40; /* Yellow top border for animation */
  border-radius: 50%; /* Make it a circle */
  width: 60px; /* Width of the loader */
  height: 60px; /* Height of the loader */
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); } /* Start at 0 degrees */
  100% { transform: rotate(360deg); } /* Rotate 360 degrees */
}
