.bg_blur {
    backdrop-filter: blur(5px);
}
.Div_Btn{
    box-shadow: 0px 4px 8px 0 rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
}
.Login_Best {
    background: linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
  }
  
  .dropdown-menu a {
    display: block;
    padding: 5px;
    text-decoration: none;
    color: #333;
  }
  
  .dropdown-menu a:hover {
    background-color: #f1f1f1;
  }
  .object_fit{
    object-fit: contain;
  }
  
.translate_y_1 {
    --tw-translate-y: 15%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
